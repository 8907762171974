<template>

  <b-modal ref="modal" v-model="showBatchOptionsModal" hide-footer>
    <b-row v-if="showError">
        <b-col lg="12">
            <b-alert variant="danger" show>{{ errorText }}</b-alert>
        </b-col>
    </b-row>

    <b-card
      title="Vänligen vänta"
      v-if="waiting"
    >

      <b-row style="height: 20px; text-align: center;">
        <b-col lg="12">
            <div class="spinner spinner-track spinner-primary spinner-lg mr-15"></div>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <p>{{$t('COMMON.WAITING')}}...</p>
        </b-col>
      </b-row>

    </b-card>

    <div v-if="!waiting">
      <b-row>
          <b-col cols="12">
            <h3 v-if="type === 'email'">{{$t('PAGES.SENDOUT.EMAIL_SENDOUT')}}</h3>
            <h3 v-if="type === 'sms'">{{$t('PAGES.SENDOUT.SMS_SENDOUT')}}</h3>
          </b-col>
      </b-row>

      <b-row v-if="type === 'sms'">
          <b-col cols="12">
            <div class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
              <div class="alert-icon"><i class="flaticon-warning"></i></div>
              <div class="alert-text">{{$t('PAGES.RENEWS.JOB_WILL_COST')}} <strong>{{ formatsek(cost_sek) }}kr</strong></div>
            </div>
          </b-col>
      </b-row>

      
      <p v-if="type === 'sms'">{{  $t('PAGES.RENEWS.SMS_COUNT_INFO') }}</p>
      <b-row v-if="type === 'sms'">
        <b-col>
          <b-form-group id="input-group-8" :label="$t('PAGES.RENEWS.NUMBER_RENEWS')" label-for="input-8">
            <b-form-input autocomplete="off"
              v-model="count_renews"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group id="input-group-9" :label="$t('PAGES.RENEWS.NUMBER_SMS_IN_TEMPLATE')" label-for="input-9">
            <b-form-input autocomplete="off"
              v-model="count_sms_in_template"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <b-form-group id="input-group-7" :label="$t('PAGES.RENEWS.NUMBER_ESTIMATED')" label-for="input-7">
            <b-form-input autocomplete="off"
              v-model="count"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6" v-if="type === 'email'">
          <b-form-group id="input-group-8" :label="$t('PAGES.RENEWS.NUMBER_SENT')" label-for="input-8">
            <b-form-input
              autocomplete="off"
              v-model="count_sent"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <p style="color: red;" v-if="type === 'sms'">{{ $t('PAGES.RENEWS.SMS_EXTRA_INFO') }}</p>

      <b-row class="mt-6">
          <b-col cols="6">
          <b-button variant="outline-success"  @click="save">
            {{$t('PAGES.JOBS.CREATE')}}
          </b-button>
          </b-col>
          <b-col cols="6">
          <b-button variant="outline-primary" @click="cancel">
            {{$t('COMMON.CLOSE')}}
          </b-button>
          </b-col>
      </b-row>
    </div>
  </b-modal>

</template>

<script>
import axios from 'axios';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';
import { format_sek } from '@/core/services/currency';

export default {
  name: 'SelectRenewsForBatch',
  components: {

  },
  data() {
    return {
      count_renews: 0,
      count_sms_in_template: 0,
      selection_option: 'ALL_PERIOD',
      waiting: false,
      status: 'ALL',
      showError: false,
      errorText: this.$t('COMMON.AN_ERROR_OCCURED'),
      showBatchOptionsModal: false,
      count: 0,
      count_sent: 0,
      cost_sek: 0,
      template: null,
      final_renew_ids: []
    };
  },
  mixins: [ toasts ],
  props: ['type','selected_renew_ids','options'],
  emits: ['cancel'],
  watch: {
  },
  computed: {
    ...mapGetters(['currentPeriodId']),
  },
  async mounted() {
    
  },
  methods: {
    formatsek(sum) {
      return format_sek(sum);
    },

    getCostForSMSBatch(num_sms) {
      if (this.type !== 'sms') {
        return;
      }

      axios
        .get(`/billing/calc_sms_cost?count=${this.count}`)
        .then(res => {
          if (res.status === 200) {
            this.cost_sek = res.data.cost_sek;
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.RENEWS.UNABLE_CALC_SMS'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.RENEWS.UNABLE_CALC_SMS'));
        });

    },

    show() {
      this.showBatchOptionsModal = true;
      this.get_renew_ids_from_selection_option(this.selection_option);
    },
    close() {
      this.showBatchOptionsModal = false;
    },
    cancel() {
      this.$refs['modal'].hide();
      this.$emit('cancel');
    },
    save() {
      if (!this.selected_renew_ids || this.selected_renew_ids.length === 0) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.RENEWS.NO_SELECTION'));
        return;
      }

      this.waiting = true;

      this.postBatchRenewSendout(this.selected_renew_ids);
    },

    async get_renew_ids_from_selection_option(selection_option) {

      try {
        const res = await axios.post(`/member_renew/batch/query?selection_option=${selection_option}&period_id=${this.currentPeriodId}&type=${this.type}`, {
          options: this.options
        });

        if (res.status < 400) {
          this.template = res.data.template;
          
          if (this.type === 'sms') {
            this.count_sms_in_template = this.template.num_sms;
          }

          this.count_renews = res.data.renew_ids.length;
          this.count = this.count_sms_in_template === 0 ? this.count_renews : this.count_renews * this.count_sms_in_template;
          this.final_renew_ids = res.data.renew_ids;

          if (this.count === undefined) {
            this.count = 0;
          }

          this.getCostForSMSBatch(this.count);
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.JOBS.UNABLE_GET'));
        }
      
      }
      catch (err) {
        console.error();
      }

    },

    postBatchRenewSendout(renew_ids) {
      this.waiting = true;

      axios
        .post(`/member_renew/batch/${this.type}`, { renew_ids: renew_ids })
        .then(res => {
          this.waiting = false;
          if (res.status === 201) {
            this.count_sent = res.data.num_sent;
            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.JOBS.CREATED_WITH_COUNT') + this.count_sent);
          }
          else {
            console.error(res.data);
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.JOBS.UNABLE_CREATE'));
          }
        })
        .catch(err => {
          this.waiting = false;
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.JOBS.UNABLE_CREATE'));
        });
    },

  }
};
</script>
<style scoped>
</style>

